import { ChangeEvent, useState } from 'react'
import Button from '../../../components/common/Button'
import FlexWrapper from '../../../components/common/FlexWrapper'
import Input from '../../../components/common/Input'
import ProgressBar from '../../../components/common/ProgressBar'
import './styles.sass'
import { useNavigate } from 'react-router-dom'
import { SubmitHandler, useForm } from "react-hook-form";
import MaskedInput from "../../../components/common/MaskedInput";

interface IFormInputs {
  firstName: string,
  lastName: string,
  phoneNumber: string,
  password: string,
  confirmPassword: string
}


const SignUp = () => {
  const navigate = useNavigate();
  const { register, formState: { errors }, handleSubmit, control } = useForm<IFormInputs>();
   const [passwordStrength, setPasswordStrength] = useState(0);

  const checkPasswordStrength = (password: string) => {
    const conditions = [
      /.{8,}/,
      /(?=.*[a-z])(?=.*[A-Z])/,
      /[0-9]/,
      /[!@#$%^&*(),.?":{}|<>]/,
    ];

    const passedConditions = conditions.reduce((acc, condition) => acc + (condition.test(password) ? 1 : 0), 0);
    setPasswordStrength(passedConditions);
  }

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if(name === 'password') {
      checkPasswordStrength(value);
    }
  }

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    console.log(data);
    // navigate('/auth/additional-info');
  }

  return (
    <div className='page-sign-up'>
      <h1 className="page-sign-up__title">
        Ro'yxatdan o'tish
      </h1>

      <ProgressBar currentStep={1} style={{marginTop: 24}}/>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexWrapper>
          <Input
            label='Ism'
            required
            {...register('firstName', {required: "Please enter your first name"})}
            error={errors.firstName?.message}
          />
          <Input
            label='Familiya'
            {...register('lastName', {required: "Please enter your last name"})}
            error={errors.lastName?.message}
            required
          />
        </FlexWrapper>
        <MaskedInput
          label="Telefon raqam"
          name="phoneNumber"
          control={control}
          mask="+{998} (00) 000 00 00"
          unmask
          placeholder='+998 (00) 000 00 00'
          required
          rules={{ required: 'Please enter your phone number' }}
          formatAsPhoneNumber
        />
        <Input
          label='Parol'
          type='password'
          {...register('password', {required: "Please enter password"})}
          error={errors.password?.message}
          required
          placeholder='parolni kiriting'
        />
        <Input
          label='Parolni qayta kiriting'
          type='password'
          {...register('confirmPassword', {required: "Please confirm the password"})}
          error={errors.confirmPassword?.message}
          required
          placeholder='parolni kiriting'
        />

        <div className="password-strength">
          <p className="password-strength__descr">
            Kalit so'z mustahkamligi
          </p>
          <ProgressBar
            style={{marginTop: 12}}
            currentStep={passwordStrength}
            stepsCount={4}
            size='sm'
          />

          <h4 className='password-strength__title'>
            Parolingizda quyidagilar bo'lishi kerak:
          </h4>
          <ul className='password-strength__list'>
            <li className='password-strength__list-item'>
              Kamida 8 ta belgi
            </li>

            <li className='password-strength__list-item'>
              Katta va kichik harflar
            </li>

            <li className='password-strength__list-item'>
              Raqam
            </li>

            <li className='password-strength__list-item'>
              Maxsus belgi (%, $, # va boshqalar)
            </li>
          </ul>
        </div>

        <Button
          size='lg'
          type='submit'
          style={{marginTop: 16}}
          fullWidth
        >
          Ro'yxatdan o'tish
        </Button>
      </form>
    </div>
  )
}

export default SignUp
